<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-lg-12">
                <div class="darmin card header-card theme-form">
                    <div class="row">
                        <div class="col">
                            <div class="row m-0 p-0 g-0">
                                <label class="col-md-3 col-form-label">Especialista</label>
                                <div class="col-md-9">
                                    <Autocomplete
                                        :debounce="1200"
                                        @input="getEspecialistas"
                                        :items="especialistas"
                                        :displayItem="
                                            (item) =>
                                                typeof item === 'string' ? item : item.fullName
                                        "
                                        placeholder="Buscar un Especialista"
                                        @onSelect="onSelectEspecialista"
                                        ref="especialistaAutocomplete"
                                    ></Autocomplete>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
                <Calendar
                    :view="calendarViewMode"
                    :especialista="especialista"
                    :intervalsDisabled="eventsSetup"
                    @onResizeWindows="handleResizeWindows"
                    @onGetIntervalDisabled="handleGetIntervalDisabled"
                    ref="calendar"
                    :key="calendarKey"
                    :hourHeight="hourHeight"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ formTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <EventForm ref="eventForm" @onSave="handleSaveEvent" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>Configuracion</h5>
                    </div>
                    <div class="card-body">
                        <div class="card-block row">
                            <div class="col-sm-12 col-lg-12 col-xl-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Modo</th>
                                                <th>Configuracion</th>
                                                <th>H. inicio</th>
                                                <th>H. fin</th>
                                                <th>...</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <IntervalDisabledTableRow
                                                v-for="(id, i) in intervalsDisabled"
                                                :key="i"
                                                :item="id"
                                                @onEdit="handleEditIntervalDisabled"
                                                @onRemove="handleRemoveIntervalDisabled"
                                            />
                                            <EventTableRow
                                                v-for="(e, i) in events"
                                                :key="i"
                                                :item="e"
                                                @onEdit="handleEditEvent"
                                                @onRemove="handleRemoveEvent"
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { computed, onBeforeMount, ref, watch } from 'vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import WindowLayout from '@/layouts/Window.layout.vue';
import EventForm from './components/EventForm.vue';
import Calendar from '../../../components/calendar/calendar.vue';
import useResize from '../../../services/useResize';
import useDoctor from '../Doctores/services/useDoctor';
import { useEventType } from '../Schedule/services/useEventType';
import useIntervalDisabled from './services/useIntervalDisabled';
import IntervalDisabledTableRow from './components/IntervalDisabledTableRow.vue';
import EventTableRow from './components/EventTableRow.vue';

export default {
    name: 'AgendaEventsForm',
    components: {
        WindowLayout,
        Autocomplete,
        EventForm,
        Calendar,
        IntervalDisabledTableRow,
        EventTableRow,
    },
    props: {
        title: { type: String, default: 'Configuracion de Citas' },
        hourHeight: {
            type: Number,
            default: 25,
        },
    },
    setup() {
        const formTitle = ref('Crear/Editar Periodo');
        const calendarViewMode = ref('week');
        const calendarKey = ref(Date.now());
        const especialista = ref();
        const eventForm = ref();

        const {
            events,
            getEvents,
            buildFilterForSetup: buildEventsFilterForSetup,
            buildEvents,
            createEvent,
            updateEvent,
            removeEvent,
        } = useEventType();
        // eslint-disable-next-line operator-linebreak
        const {
            intervalsDisabled,
            getIntervalDisabled,
            buildFilterForSetup,
            buildIntervalDisable,
            createIntervalDisable,
            updateIntervalDisable,
            removeIntervalDisabled,
        } = useIntervalDisabled();
        const { doctores: especialistas, getDoctores: getEspecialistas } = useDoctor();
        const { width } = useResize();

        const especialistaAutocomplete = ref(); // autocomplete input
        const especialistaSelected = ref();
        const onSelectEspecialista = (doctor) => {
            console.log(doctor.id);
            especialista.value = doctor.id;
            especialistaAutocomplete.value.setText(doctor.fullName);
            especialistaSelected.value = doctor;
            calendarKey.value = Date.now();
            getIntervalDisabled(buildFilterForSetup(especialista.value));
            getEvents(buildEventsFilterForSetup(especialista.value));
        };

        const handleGetIntervalDisabled = () => {
            getIntervalDisabled(buildFilterForSetup(especialista.value));
        };

        const handleResizeWindows = (widthVal) => {
            if (widthVal < 576) {
                calendarViewMode.value = 'day';
            } else {
                calendarViewMode.value = 'week';
            }
        };

        watch(
            () => width.value,
            (newVal) => {
                handleResizeWindows(newVal);
                // eslint-disable-next-line comma-dangle
            }
        );

        onBeforeMount(() => {
            handleResizeWindows(width);
        });

        const eventsSetup = computed(() => {
            const ev = [];
            events.value.forEach((e) => {
                // eslint-disable-next-line object-curly-newline
                const { id, name, especialista: especialistaId, schedule } = e;
                schedule.forEach((s) => {
                    // eslint-disable-next-line object-curly-newline
                    const { weekDay, startHour, startMin, startSec, endHour, endMin, endSec } = s;
                    // eslint-disable-next-line object-curly-newline
                    ev.push({
                        id,
                        weekDay,
                        startHour,
                        startMin,
                        startSec,
                        endHour,
                        endMin,
                        endSec,
                        class: name,
                        type: name,
                        especialista: especialistaId,
                    });
                });
            });
            console.log('ev', ev);
            return [...ev, ...intervalsDisabled.value];
        });

        const handleEditEvent = (event) => {
            eventForm.value.setEvent(event);
        };

        const handleEditIntervalDisabled = (event) => {
            eventForm.value.setIntervalDisabled(event);
        };

        const handleSaveEvent = (dataForm) => {
            console.log('event', dataForm);
            if (dataForm.formType !== 'blocked') {
                const event = buildEvents({
                    name: dataForm.formType,
                    types: dataForm.formTypes,
                    days: dataForm.formDays,
                    timeStart: dataForm.formTimeStart,
                    timeEnd: dataForm.formTimeEnd,
                    period: dataForm.formPeriodo,
                    especialistaId: especialista.value,
                });
                if (dataForm.id) {
                    updateEvent(dataForm.id, event);
                } else {
                    createEvent(event);
                }
            } else {
                const iD = buildIntervalDisable({
                    mode: dataForm.formMode,
                    date: dataForm.formDate,
                    day: dataForm.formDay,
                    timeStart: dataForm.formTimeStart,
                    timeEnd: dataForm.formTimeEnd,
                    especialistaId: especialista.value,
                });

                if (dataForm.id) {
                    updateIntervalDisable(dataForm.id, iD);
                } else {
                    createIntervalDisable(iD);
                }
            }
        };

        const handleRemoveEvent = (id) => {
            removeEvent(id);
        };

        const handleRemoveIntervalDisabled = (id) => {
            removeIntervalDisabled(id);
        };

        return {
            // Especialista Autocomplete
            especialista,
            especialistas,
            especialistaAutocomplete,
            especialistaSelected,
            getEspecialistas,
            onSelectEspecialista,
            // Calendar
            calendarKey,
            calendarViewMode,
            handleResizeWindows,
            handleGetIntervalDisabled,
            // Formulario
            formTitle,
            eventForm,
            handleSaveEvent,
            // Table
            intervalsDisabled,
            events,
            eventsSetup,
            handleEditEvent,
            handleEditIntervalDisabled,
            handleRemoveIntervalDisabled,
            handleRemoveEvent,
        };
    },
};
</script>

<style scoped></style>
